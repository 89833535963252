@define-mixin visuallyHidden {
  position: absolute;

  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;

  border: 0;
  clip: rect(0 0 0 0);
}

@define-mixin outline $inner: var(--color-white) {
  box-shadow: 0 0 2px 2px $inner, 0 0 2px 3px var(--rebranding-md-color-blue);
  outline: 0;
}

@define-mixin resetButtonStyles $backgroundColor: transparent {
  padding: 0;

  background-color: $backgroundColor;
  border: 0;

  font-family: inherit;
}

@define-mixin loadingAnimation $name, $color, $width, $height {
  width: $width;
  height: $height;

  background-image: linear-gradient(
    90deg,
    transparent 0,
    color($color alpha(50%)) 50%,
    color($color alpha(50%)) 70%,
    transparent 100%
  );
  background-position: calc(-2 * $width) 0;
  background-repeat: no-repeat;
  background-size: $width $height;
  border-radius: 4px;

  animation: $name 1.5s infinite;
}

@define-mixin loadingKeyframes $name, $width {
  @keyframes $name {
    to {
      background-position: $width 0;
    }
  }
}

@define-mixin loading $name, $color, $width, $height {
  @mixin loadingAnimation $name, $color, $width, $height;
  @mixin loadingKeyframes $name, $width;
}

@custom-media --adaptive screen and (min-width: 500px);

@custom-media --breakpoint-mobile screen and (min-width: 986px);

@custom-media --breakpoint-desktop screen and (min-width: 1040px);
