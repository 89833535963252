@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;
  padding: var(--size-28) 0;
  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    flex-direction: row;
  }
}

.main {
  @media (--breakpoint-desktop) {
    width: var(--6-cols);
  }
}

.secondary {
  padding-top: var(--size-20);

  > * {
    margin-bottom: var(--size-20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (--breakpoint-desktop) {
    width: var(--4-cols);
    padding: 0 var(--gutter);
  }
}
