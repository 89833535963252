@import 'src/common/styles/settings.module.css';

:root {
  --backdrop-z-index: 100;
}

.root {
  position: relative;

  display: inline-block;
}

.backdrop {
  position: fixed;

  top: 0;
  left: 0;

  z-index: var(--backdrop-z-index);

  width: 100%;
  height: 100%;
}

.backdropHidden {
  visibility: hidden;
}

.toggler {
  display: flex;

  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.content {
  position: absolute;

  z-index: calc(var(--backdrop-z-index) + 1);

  display: none;

  width: var(--size-124);

  padding: 0 var(--size-12);

  background: var(--color-white);

  border: 1px solid var(--rebranding-md-color-grey-10);
  border-radius: 2px;

  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);
}

.visible {
  display: inline;
}
