@import 'src/common/styles/settings.module.css';

.displayWrapper {
  position: relative;

  width: 100%;
  min-height: var(--size-36);
}

.code,
.copiedToClipboard {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  min-height: var(--size-36);
  padding: var(--size-8) var(--size-12);

  border-radius: var(--size-4);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-20);

  @media (--breakpoint-desktop) {
    padding: var(--size-8) var(--size-44) var(--size-8) var(--size-12);
  }
}

.copiedToClipboard {
  justify-content: center;
  padding: var(--size-8) var(--size-20) var(--size-8) var(--size-32);

  background: var(--rebranding-md-color-green);
  color: var(--color-white);
}

.code {
  background: var(--rebranding-md-color-grey-10);
  border: none;
  color: var(--rebranding-color-black);

  word-break: break-all;
  word-spacing: normal;
  word-wrap: break-word;

  &:focus {
    outline: auto;
  }

  &.isButton {
    cursor: pointer;
  }

  &.isLoading {
    width: 100%;
    height: 100%;
    margin-right: var(--size-28);

    background: none;
    background-image: linear-gradient(
      90deg,
      transparent 0,
      rgba(245, 245, 255, 0.8) 30%,
      rgba(245, 245, 255, 0.8) 70%,
      transparent 100%
    );
    background-position: -195px 0;
    background-repeat: no-repeat;
    border-radius: 2px;

    animation: loading-animation 1s infinite;

    > * {
      display: none;
    }
  }

  &.isHidden {
    color: var(--rebranding-md-color-grey-70);
  }
}

.fullWidth {
  width: 100%;
}

@keyframes loading-animation {
  to {
    background-position: 195px 0;
  }
}

.text {
  width: calc(100% - var(--size-24));

  font-size: var(--size-14);

  overflow-wrap: break-word;

  text-align: left;

  @media (--breakpoint-desktop) {
    width: 100%;
  }
}

.clipboardIcon {
  position: absolute;
  right: var(--size-12);
}

.shareIcon {
  background: transparent;

  border: none;
}

.message {
  position: relative;

  padding-left: var(--size-4);

  font-size: var(--size-16);
  line-height: var(--size-20);
}

.checkIcon {
  position: absolute;
  left: calc(-1 * var(--size-20));

  svg path {
    fill: var(--color-white);
  }
}
