@import 'src/common/styles/settings.module.css';

.root {
  width: 100%;

  font-family: var(--font-family-roobert-pro);
  font-variant-numeric: lining-nums tabular-nums;
}

.optionsHolder {
  position: relative;
}

.options {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;

  overflow: auto;

  background: var(--color-white);
  border-color: var(--rebranding-md-color-brand-purple);

  border-style: solid;
  border-width: 0px 1px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: var(--rebranding-md-color-brand-black);

  transition: max-height 0.3s ease-in-out;

  &.has-items {
    border-bottom-width: 1px;
  }
}
