@import 'src/common/styles/settings.module.css';

.root {
  padding: var(--size-12) var(--size-20) var(--size-20);
  margin: var(--size-28) 0;

  @media (--breakpoint-desktop) {
    width: calc(var(--5-cols) + calc(2 * var(--gutter)));

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.errorMessage {
  margin-top: var(--size-14);

  color: var(--rebranding-md-color-red);
}

.buttonWrapper {
  @media (--breakpoint-desktop) {
    width: var(--2-cols);
  }
}
