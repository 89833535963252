@import 'src/common/styles/settings.module.css';

.root {
  position: relative;

  display: flex;
  align-items: center;
  margin-bottom: var(--size-36);
}

.root::after {
  content: '';

  position: absolute;
  top: var(--size-36);
  left: 17px;

  width: 2px;
  height: var(--size-36);

  background-color: var(--rebranding-md-color-grey-20);
}

.root.completed::after {
  background-color: var(--rebranding-md-color-green);
}

.root:last-child {
  margin: 0;

  &::after {
    content: none;
  }
}

.icon {
  position: relative;

  width: var(--size-36);
  height: var(--size-36);
  margin-right: var(--size-20);

  border-radius: 50%;

  &.completed {
    background-color: var(--rebranding-md-color-green);
  }

  &.current {
    border: 2px solid var(--rebranding-md-color-green);
  }

  &.idle {
    border: 2px solid var(--rebranding-md-color-grey-50);
  }
}

.checkmark {
  position: absolute;
  top: var(--size-4);
  left: var(--size-4);

  width: var(--size-28);
}

.text {
  &.completed {
    color: var(--rebranding-md-color-green);
  }

  &.current {
    color: var(--rebranding-color-black);
  }

  &.idle {
    color: var(--rebranding-md-color-grey-50);
  }
}
