@import 'src/common/styles/settings.module.css';

.reference {
  overflow-wrap: break-word;
}

.strong {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.fieldWrapper {
  margin-top: var(--size-20);
}

.pill {
  width: var(--size-40);
  padding: var(--size-4) var(--size-12);

  border-radius: var(--size-100);
  color: var(--color-white);

  font: var(--size-16) var(--font-family-roobert-pro);
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.failed {
  background-color: var(--rebranding-md-color-red);
}

.pending {
  background-color: var(--rebranding-md-color-orange);
}

.success {
  background-color: var(--rebranding-md-color-green);
}
