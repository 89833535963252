@import 'src/common/styles/settings.module.css';

.headingWrapper {
  margin: var(--size-28) 0 0 var(--gutter);
  @media (--breakpoint-desktop) {
    margin: var(--size-28) 0 var(--size-20) var(--gutter);
  }
}

.ongoingBody {
  width: 100%;
  padding: 0 var(--size-20);

  margin-bottom: var(--size-32);

  @media (--breakpoint-desktop) {
    max-width: calc(var(--7-cols) + var(--gutter) * 2);

    margin-bottom: 0;

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.divider {
  margin: var(--size-20) 0;

  border: 0;
  border-top: 1px solid var(--rebranding-md-color-grey-10);
}

.extendedBadge {
  display: flex;

  padding: var(--size-8);

  margin-top: calc(0px - var(--size-8));
  margin-left: var(--size-8);

  background-color: rgba(235, 113, 0, 0.05);

  border: 1px solid var(--rebranding-md-color-orange);

  border-radius: 100px;

  cursor: default;
}

.ongoingRow {
  display: flex;
  flex-direction: column;
  margin: var(--size-20) 0;

  @media (--breakpoint-desktop) {
    flex-direction: row;
  }
}

.ongoingField {
  a {
    color: unset;

    text-decoration: none;
  }

  &:not(:last-of-type) {
    margin-bottom: var(--size-28);
  }

  @media (--breakpoint-desktop) {
    width: 30%;

    &:nth-of-type(2) {
      width: 40%;
    }

    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
}

.longField {
  @media (--breakpoint-desktop) {
    width: unset;
  }
}

.addressWrapper {
  display: none;

  @media (--breakpoint-desktop) {
    display: flex;
    width: calc(280px);

    & > div {
      flex-grow: 1;
    }
  }
}

.addressWrapperMobile {
  display: contents;

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.blockchainWrapper {
  margin-top: 28px;

  @media (--breakpoint-desktop) {
    flex-grow: 1;
    margin-top: 0;

    & > div {
      width: 100%;
    }
  }
}

.iconWrapper {
  margin-right: var(--size-4);
}

.ongoingFieldLabel {
  display: flex;

  margin-bottom: var(--size-12);
}

.downloadLink {
  display: flex;
}

.downloadText {
  margin-right: var(--size-8);
}
