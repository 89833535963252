@import 'src/common/styles/settings.module.css';
@import '../index.module.css';

.container {
  display: flex;

  &:not(:last-child) > .events {
    padding-bottom: var(--size-20);
  }
}

.date {
  position: relative;

  display: flex;

  width: var(--date-width);
  padding-right: var(--size-4);
  margin-right: var(--gutter);

  color: var(--rebranding-md-color-grey-60);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.date::after {
  content: '';

  position: absolute;
  right: var(--outer-circle-position);
  z-index: var(--activities-date-z-index);

  width: var(--outer-circle-size);
  height: var(--outer-circle-size);

  background-color: var(--color-gray-blue);
  border-radius: 50%;
}

.events {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    padding-bottom: var(--size-20);
  }
}
