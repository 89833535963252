@import 'src/common/styles/settings.module.css';

.root {
  display: inline-flex;
  padding: 2px;

  border-radius: var(--size-20);
  color: var(--color-white);

  font-size: var(--size-16);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
  line-height: var(--size-20);

  @media print {
    display: none;
  }
}

.wrapper {
  display: flex;

  align-items: center;
  justify-content: center;
  width: var(--size-20);
  height: var(--size-20);

  background: var(--rebranding-color-white);
  border-radius: 50%;
}

.label {
  margin: 0 var(--size-8);
}

.printingLabel {
  display: none;

  font-weight: var(--font-weight-roobert-pro-semi-bold);
  @media print {
    display: block;
  }
}
