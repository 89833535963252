.iconWrapper {
  width: 104px;
  margin: var(--size-48);

  @media (--breakpoint-desktop) {
    width: 144px;
  }
}

.resendMessage {
  margin-top: var(--size-28);
  span {
    color: var(--rebranding-md-color-brand-purple);

    cursor: pointer;

    text-decoration: none;
  }

  span:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}
