@import 'src/common/styles/settings.module.css';

.cell {
  background-color: var(--color-white);
}

.cell-content {
  position: relative;

  display: flex;
  flex-direction: column;

  margin: var(--size-12) var(--gutter);

  vertical-align: middle;

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.bold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.right {
  justify-content: flex-end;

  text-align: right;
}
