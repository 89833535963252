@import 'src/common/styles/settings.module.css';

.steps {
  color: var(--rebranding-md-color-brand-black);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  line-height: var(--size-20);
  overflow-wrap: break-word;

  a {
    color: var(--rebranding-md-color-brand-purple);

    text-decoration: none;
  }

  a:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }

  li {
    margin-top: var(--size-16);

    list-style: decimal;
    list-style-position: inside;
  }
}

.stepsWrapper {
  width: 100%;
}

.subStepsWrapper {
  margin: var(--size-12) 0 var(--size-20) var(--size-12);
}

.bold {
  color: var(--rebranding-md-color-brand-black);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.iconWrapper {
  margin-right: var(--size-4);
  margin-left: var(--size-4);
}

.row {
  display: flex;
  align-items: flex-end;

  margin-right: var(--size-16);
  margin-bottom: var(--size-8);
}

.codeRow {
  display: flex;
  align-items: flex-end;

  margin-bottom: var(--size-8);
}

.note {
  margin-top: var(--size-80);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
