@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;
}

.padding-full {
  padding: var(--size-12) var(--gutter);
}

.padding-horizontal {
  padding: 0 var(--gutter);
}

.padding-vertical {
  padding: var(--size-12) 0;
}

.border-full {
  border: none;

  @media (--breakpoint-desktop) {
    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.border-no-bottom {
  border: none;

  @media (--breakpoint-desktop) {
    border: 1px solid var(--rebranding-md-color-grey-10);
    border-bottom: none;
  }
}
