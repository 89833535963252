@import 'src/common/styles/settings.module.css';

.root {
  display: grid;
  padding-top: var(--size-36);
  row-gap: var(--size-28);

  margin-bottom: var(--size-36);

  @media (--breakpoint-desktop) {
    display: flex;
    column-gap: var(--size-28);
  }
}
