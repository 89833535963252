@import 'src/common/styles/settings.module.css';

.list {
  margin-top: var(--size-14);
  margin-bottom: var(--size-14);

  list-style-position: inside;
  list-style-type: unset;
}

.listItem::before {
  content: '•';

  position: absolute;

  left: calc(-2 * var(--size-8));

  size: var(--size-14);
}

.listItem {
  position: relative;

  margin-left: var(--size-20);

  color: var(--rebranding-md-color-grey-60);

  list-style: none;
}

.listItem:not(:first-child) {
  margin-top: var(--size-8);
}

.field {
  margin-top: var(--size-36);
  margin-bottom: var(--size-36);
}

.sectionTitle {
  margin-bottom: var(--size-4);

  color: var(--rebranding-color-black);

  font-size: var(--size-20);
  line-height: var(--size-28);
}
