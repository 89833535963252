@import 'src/common/styles/settings.module.css';

.headingWrapper {
  padding-top: var(--size-12);
}

.logoWrapper {
  width: var(--size-merchant-logo-mobile);

  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    width: var(--size-merchant-logo);

    margin-bottom: var(--size-24);
  }
}

.sandboxLink {
  margin: var(--size-20) 0 var(--size-32);

  @media (--breakpoint-desktop) {
    position: absolute;

    bottom: var(--size-20);

    margin: 0;
  }
}

.productionLink {
  margin-top: var(--size-12);
}

.list {
  margin-top: var(--size-20);
}

.listItem::before {
  content: '•';

  position: absolute;

  left: calc(-1 * var(--size-8));

  size: var(--size-14);
}

.listItem {
  position: relative;

  margin-left: var(--size-8);

  list-style: none;
}

.listItem:not(:first-child) {
  margin-top: var(--size-20);
}
