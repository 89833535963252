@import 'src/common/styles/settings.module.css';

.root {
  display: grid;
  grid-row-gap: var(--size-12);
}

.header {
  margin-bottom: var(--size-4);

  color: var(--rebranding-md-color-grey-60);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-14);
  line-height: var(--size-16);

  @media print {
    font-weight: var(--font-weight-roobert-pro-semi-bold);
  }
}

.codeDisplay {
  display: flex;
}
