@import 'src/common/styles/settings.module.css';

:root {
  --date-width: calc(var(--cell-length) - var(--gutter));
  --timeline-position: calc(var(--date-width) + 9px);
  --first-element-height: 42px;
  --outer-circle-size: 20px;
  --outer-circle-position: calc(
    calc(var(--gutter) / -2) - calc(var(--outer-circle-size) / 2)
  );
  --activities-date-z-index: 1;
  --activities-circle-z-index: calc(var(--activities-date-z-index) + 1);
}

.wrapper {
  display: flex;

  align-items: center;
  justify-content: center;

  width: var(--size-20);
  height: var(--size-20);

  border-radius: 50%;
}

.root {
  position: relative;

  flex: 1;

  margin-top: var(--size-24);

  font-family: var(--font-family-roobert-pro);
}

.root::before {
  content: '';

  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--timeline-position);

  width: 2px;
  margin: var(--size-8) 0 var(--first-element-height);

  background-color: var(--rebranding-md-color-grey-60);
  opacity: 0.2;
}
