@import 'src/common/styles/settings.module.css';

.root {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--size-12);

  margin: var(--size-28) var(--size-20) 0;

  font-family: var(--font-family-roobert-pro);
}

.header {
  margin-right: var(--size-28);
}

.tab {
  position: relative;

  display: inline;
  padding-bottom: 3px;
  overflow: hidden;

  color: var(--rebranding-md-color-grey-60);
  cursor: pointer;

  font-size: var(--size-20);
  line-height: var(--size-28);
  text-decoration: none;
}

.tab:after {
  content: '';

  position: absolute;
  right: 100%;
  bottom: -1px;
  left: 0;
  z-index: -1;

  height: 2px;

  background: var(--rebranding-md-color-grey-60);

  transition-duration: 0.3s;
  transition-property: left right;
  transition-timing-function: ease-out;
}

.tab.active {
  color: var(--rebranding-md-color-brand-purple);
}

.tab:hover::after,
.tab:focus::after,
.tab:active::after,
.tab.active::after {
  right: 0;
}

.tab.active::after {
  background: var(--rebranding-md-color-brand-purple);
}
