@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table {
  width: 100%;
  margin: var(--size-20) 0;

  border: 1px solid var(--rebranding-md-color-grey-10);

  th,
  td  {
    &:nth-child(1) {
      width: var(--3-cols);
    }
    &:nth-child(2) {
      width: calc(var(--cell-length) + var(--gutter));
    }
    &:nth-child(3) {
      width: var(--2-cols);
    }
    &:nth-child(4) {
      width: var(--2-cols);
    }
    &:nth-child(5) {
      width: var(--2-cols);
    }
  }
}

.header {
  display: none;

  background-color: var(--rebranding-md-color-brand-purple-lightest);

  @media (--breakpoint-desktop) {
    display: revert;
  }
}

.paginationWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: var(--size-20);

  padding-bottom: var(--size-20);
  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
  }
}

.paginationHelper {
  column-span: 1/2;
}

.pagination {
  column-span: 2/3;
}
