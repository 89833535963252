@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--size-14) var(--size-20) var(--size-14) var(--size-20);

  background: var(--rebranding-md-color-brand-purple);

  @media (--breakpoint-desktop) {
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--size-40) 0 0 0;
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;

  width: 28px;

  padding: 0;

  > * {
    display: flex;
  }

  @media (--breakpoint-desktop) {
    width: var(--size-52);

    margin-bottom: 0;
    margin-left: var(--size-36);
  }
}

.navButton {
  display: inline;
  width: var(--size-28);

  &:focus {
    outline: none;
  }

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.nav {
  display: none;

  margin-top: var(--size-12);
  margin-bottom: auto;

  outline: none;

  @media (--breakpoint-desktop) {
    display: inline;
  }
}

li {
  list-style: none;
}

.mobileMenu {
  position: fixed;

  right: calc(-50%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  height: 100%;

  padding-bottom: var(--size-48);

  background: var(--rebranding-md-color-brand-purple);

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.signOutWrapper {
  display: none;

  @media (--breakpoint-desktop) {
    display: inline;
  }
}
