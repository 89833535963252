@import 'src/common/styles/settings.module.css';

.cell {
  display: flex;
  flex-wrap: wrap;
  margin: var(--size-12) var(--size-20);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.helper {
  font-weight: var(--font-weight-regular);
  @media (--adaptive) {
    white-space: pre-wrap;
  }
}

.right {
  justify-content: flex-end;
}
