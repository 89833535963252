@import 'src/common/styles/settings.module.css';
.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: var(--size-20);

  padding-bottom: var(--size-4);
  margin-right: 2px;

  &:focus {
    box-shadow: 0;
    outline: 0;
  }

  cursor: pointer;
}
