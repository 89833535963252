@import 'src/common/styles/settings.module.css';

.root {
  color: var(--rebranding-md-color-brand-black);

  font-family: var(--font-family-roobert-pro);

  font-variant-numeric: lining-nums tabular-nums;
}

.h1 {
  font-size: var(--size-36);
  line-height: var(--size-52);
  composes: root;
}

.h2 {
  composes: root;

  font-size: var(--size-28);
  line-height: var(--size-36);
}

.h3 {
  composes: root;

  font-size: var(--size-20);
  line-height: var(--size-28);
}

.regular {
  font-weight: var(--font-weight-regular);
}

.medium {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.semiBold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.h2,
.h3 {
  &.white {
    color: var(--color-white);
  }
}

.black {
  color: var(--rebranding-md-color-brand-black);
}

.blue {
  color: var(--rebranding-md-color-brand-purple);
}

.grey-60 {
  color: var(--rebranding-md-color-grey-60);
}

.grey-40 {
  color: var(--rebranding-md-color-grey-40);
}

.green {
  color: var(--rebranding-md-color-green);
}
