@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  width: 100vw;

  padding: var(--size-80) var(--size-20);

  text-align: center;

  @media (--breakpoint-desktop) {
    width: unset;
    height: 100%;
    margin-bottom: var(--size-64);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--breakpoint-desktop) {
    margin: auto 0;
  }
}

.message {
  margin-bottom: var(--size-20);

  text-align: center;
}

.buttonWrapper {
  width: 100%;
  margin-top: var(--size-28);
  @media (--breakpoint-desktop) {
    display: none;
  }
}

.link {
  a {
    color: var(--rebranding-md-color-brand-purple);

    text-decoration: none;
  }

  a:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}
