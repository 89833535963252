@import 'src/common/styles/settings.module.css';

.error {
  padding: var(--size-4);

  color: var(--rebranding-md-color-red);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-14);
  line-height: var(--size-16);
}
