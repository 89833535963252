html,
body {
  height: 100%;

  color: #252d3d;

  font: 16px / 20px 'Roobert Pro', helvetica arial, sans-serif;
  font-variant-numeric: lining-nums tabular-nums;
}

:global .root {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > * {
    flex: 1 1 auto;
  }

  @media print {
    display: block !important;
  }
}
