@import 'src/common/styles/settings.module.css';

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-bottom: var(--size-4);
  > * {
    flex: 1;
  }
}

.labelRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.columns {
  flex: 1 0 auto;
  display: flex;
}

.column {
  width: 50%;

  &:not(:first-child) {
    margin-left: var(--gutter);
  }
}

.divider {
  margin-top: var(--size-36);
  margin-bottom: var(--size-20);

  border: 0;
  border-top: 1px solid var(--rebranding-md-color-grey-10);
}

.formFooter {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.buttonWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: var(--size-84);
  }
}

.countryDescription {
  margin-top: var(--size-4);

  color: var(--rebranding-md-color-grey-60);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-14);

  line-height: var(--size-16);

  a {
    color: var(--rebranding-md-color-brand-purple);

    text-decoration: none;
    &:hover {
      color: var(--rebranding-md-color-brand-purple-dark);
    }
  }
}

.infoBox {
  padding: var(--size-16);

  margin-top: var(--size-12);

  background-color: var(--rebranding-md-color-blue-lightest);
  border-radius: var(--size-4);
  color: var(--rebranding-md-color-blue-darkest);

  font-size: var(--size-14);

  line-height: var(--size-16);

  a {
    color: inherit;

    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
}

.infoBoxButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-16);

  margin-top: var(--size-16);
}

.infoBoxButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-4);
}

.infoBoxButtonLink {
  color: var(--rebranding-md-color-blue-darkest);

  font-size: var(--size-14);
  line-height: var(--size-16);
}
