@import 'src/common/styles/settings.module.css';

:root {
  --backdrop-z-index: 100;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--backdrop-z-index);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--backdrop-z-index);

    display: flex;
    width: 100%;
    height: 100%;

    background: color(var(--color-white) alpha(50%));

    /* Browsers with backdrop-filter support */
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(7px);
    }

    /* Fallback */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: var(--rebranding-md-color-grey-20);
    }
  }
}

.modal {
  z-index: calc(var(--backdrop-z-index) + 1);

  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;

  height: 100%;

  max-width: 100vw;

  padding: var(--size-20);

  overflow: auto;

  background: var(--color-white);

  border: 1px solid var(--rebranding-md-color-grey-10);

  @media (--breakpoint-desktop) {
    align-items: unset;

    justify-content: unset;

    height: unset;
  }
}
