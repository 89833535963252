@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--size-12) var(--size-20);
}

.value {
  font-size: var(--size-20);
  font-weight: var(--font-weight-roobert-pro-semi-bold);
  line-height: var(--size-28);
}
