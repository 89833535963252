@import 'src/common/styles/settings.module.css';

:root {
  --border-color: var(--rebranding-md-color-grey-30);
  --border-weight: 1px;
  --dash-size: 8px;
  --gap-size: 8px;
}

.root {
  @mixin resetButtonStyles;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: var(--size-20);

  border: var(--border-weight) dashed var(--border-color);

  outline: 0;

  &:not(:first-child) {
    margin-top: -1px;
  }

  > *:not(:first-child) {
    padding-top: var(--size-12);
  }
}

.missing {
  border-color: var(--rebranding-md-color-red);
}

.root:hover,
.root:focus {
  border: var(--border-weight) solid var(--rebranding-md-color-blue);

  p {
    color: var(--rebranding-md-color-blue);
  }
}
