@import 'src/common/styles/settings.module.css';

.root {
  padding: var(--size-12) var(--size-20);
}

.orderedList {
  margin-top: var(--size-8);

  li {
    list-style: decimal;
    list-style-position: inside;
  }
}

.list {
  margin-top: var(--size-8);
}

.subtitle {
  padding: var(--size-8) 0 var(--size-12);
}

.descriptionMobile {
  @media (--breakpoint-desktop) {
    display: none;
  }
}

.description {
  display: none;

  @media (--breakpoint-desktop) {
    display: unset;
  }
}
