.progress {
  animation: rotate 1.4s linear infinite;

  &.primary,
  &.destructive {
    svg circle {
      stroke: var(--color-white);
    }
  }

  &.secondary {
    svg circle {
      stroke: var(--rebranding-md-color-brand-purple);
    }
  }

  svg circle {
    stroke-dasharray: 80, 200;
    stroke-dashoffset: 0;

    animation: dash 1.4s ease-in-out infinite;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125;
  }
}
