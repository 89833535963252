@import 'src/common/styles/settings.module.css';
@import '../index.module.css';

:root {
  --circle-size: 12px;
  --circle-position: calc(
    calc(var(--gutter) / -2) - calc(var(--circle-size) / 2)
  );
}

.activity {
  position: relative;

  display: flex;
  flex-direction: column;
  padding-left: var(--size-20);

  font-size: var(--size-16);
  line-height: var(--size-20);
}

.activity::after {
  content: '';

  position: absolute;
  top: var(--size-4);
  left: var(--circle-position);
  z-index: var(--activities-circle-z-index);

  display: inline-block;

  width: var(--circle-size);
  height: var(--circle-size);

  background-color: white;
  border: 2px solid var(--rebranding-md-color-grey-60);
  border-radius: 50%;
}

.time {
  width: var(--cell-length);
  margin-bottom: var(--size-8);

  color: var(--rebranding-md-color-grey-60);
}

.statusWrapper {
  > *:not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.status {
  display: flex;
  align-items: center;
}

.content {
  padding-left: calc(var(--size-16) + var(--size-8));

  color: var(--rebranding-md-color-grey-60);

  font-size: var(--size-14);
  line-height: var(--size-16);
}

.statusLabel {
  padding-left: var(--size-8);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.paid {
  color: var(--rebranding-md-color-green);
}

.refundCompleted {
  color: var(--rebranding-md-color-pink);
}

.refundProposed,
.refundProcessing {
  color: var(--rebranding-md-color-orange);
}

.expired,
.canceled {
  color: var(--rebranding-md-color-grey-60);
}

.pending {
  color: var(--rebranding-md-color-blue);
}

/** avoid crash of legacy orders **/
.completed,
.dispute {
}
