@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;

  font-family: var(--font-family-roobert-pro);
}

.visuallyHidden {
  @mixin visuallyHidden;
}
