@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 1 auto;
  display: flex;

  flex-direction: column;

  padding: var(--size-24) var(--size-24) var(--size-168);

  background: var(--rebranding-color-white);
  overflow-y: auto;

  @media (--breakpoint-desktop) {
    padding: var(--size-52) 0 var(--size-200);
  }
}

.container {
  @media (--breakpoint-desktop) {
    width: var(--10-cols);
  }
}

.row {
  display: flex;

  flex-direction: column;

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--size-88);
  }
}

.column {
  margin-top: var(--size-48);

  @media (--breakpoint-desktop) {
    width: var(--5-cols);
    margin-top: 0;
    overflow-x: visible;
  }
}

.title {
  max-width: var(--2-cols);

  @media (--breakpoint-desktop) {
    max-width: calc(var(--3-cols) + var(--gutter));
  }
}

.timeline {
  margin-top: var(--size-12);
}
