@import 'src/common/styles/settings.module.css';

.modalFooter {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  margin-top: var(--size-20);

  > :first-child {
    margin-top: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;

    margin-bottom: 0;

    > :last-child {
      margin-left: var(--size-20);
    }

    > :first-child {
      margin-top: 0;
    }
  }
}
