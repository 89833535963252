@import 'src/common/styles/settings.module.css';

.iconWrapper {
  width: 67px;
  margin: var(--size-48);

  @media (--breakpoint-desktop) {
    width: 93px;
  }
}
