@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-24) var(--gutter);

  border: 1px solid var(--rebranding-md-color-grey-30);

  &:not(:first-child) {
    border-top: none;
  }
}

.link {
  margin-right: var(--size-24);

  text-align-last: left;

  word-break: break-all;
  word-wrap: break-word;
}

.labelGroup {
  display: flex;
  align-items: center;

  margin-right: var(--size-24);
}

.percentage {
  flex: 1 0 auto;

  max-width: 100%;

  padding: 0 var(--size-12);

  color: var(--rebranding-md-color-grey-60);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-weight: var(--font-weight-regular);
  line-height: var(--size-20);
  word-break: break-all;
}
