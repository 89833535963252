@import 'src/common/styles/settings.module.css';

.link {
  color: var(--rebranding-md-color-brand-purple);

  text-decoration: none;
  &:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}
