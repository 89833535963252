@import 'src/common/styles/settings.module.css';
.root {
  display: flex;
  flex-direction: column;

  color: var(--rebranding-md-color-grey-80);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-style: var(--font-weight-regular);
  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-20);
}

.wrapper {
  position: relative;

  display: flex;
  padding: var(--size-8) var(--size-12);

  background: var(--color-white);
  border: 1px solid var(--rebranding-md-color-grey-70);
  border-radius: 2px;

  &:disabled {
    border-color: var(--rebranding-md-color-grey-20);
  }

  &.required,
  &.invalid {
    border-color: var(--rebranding-md-color-red);
  }

  &.active {
    border-color: var(--rebranding-md-color-brand-purple);
  }
}

.currency {
  position: absolute;
  top: calc(50%);
  left: var(--size-12);

  color: var(--rebranding-md-color-grey-80);

  transform: translateY(-50%);
}

.input {
  flex: 1 1 auto;
  width: 0;
  padding: 0 var(--size-8) 0 calc(3 * var(--size-12));

  resize: none;

  background: transparent;
  border: none;
  color: var(--rebranding-md-color-grey-80);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-style: var(--font-weight-regular);
  line-height: var(--size-20);

  &:disabled {
    cursor: initial;
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.partialWarning {
  padding: var(--size-4) 0;

  color: var(--rebranding-md-color-grey-60);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-14);
  line-height: var(--size-16);
}

.bold {
  font-weight: bold;
}
