@import 'src/common/styles/settings.module.css';

.pageLayout {
  display: grid;
  grid-template-columns: auto;
  row-gap: var(--size-28);

  padding-bottom: var(--size-28);

  &:first-child {
    margin-top: var(--size-36);
  }
}
