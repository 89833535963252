@import 'src/common/styles/settings.module.css';

.account {
  padding-top: var(--size-12);

  &:not(:last-of-type) {
    padding-bottom: var(--size-12);

    border-bottom: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.accountName {
  display: flex;
  justify-content: space-between;
}
