@import 'src/common/styles/settings.module.css';

.content {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    padding-bottom: var(--size-20);
  }
}

.items > .lineItem:not(:last-child) {
  margin-bottom: var(--size-12);
}

.lineItem {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
