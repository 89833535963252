@import 'src/common/styles/settings.module.css';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: var(--size-12);

  @media (--breakpoint-desktop) {
    flex-direction: row;

    margin-top: 0;
    margin-left: var(--size-48);
  }
}

.field {
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    margin-left: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    margin-left: var(--size-20);
  }
}

.fieldRow {
  align-self: flex-start;

  display: flex;

  flex-direction: row;

  margin-bottom: var(--size-12);

  @media (--breakpoint-desktop) {
    margin-bottom: 0;
  }
}

.info {
  display: flex;

  svg {
    margin-left: var(--size-4);
  }
}
