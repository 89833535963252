@import 'src/common/styles/settings.module.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  padding: var(--size-12);

  background-color: var(--color-white);

  font-family: var(--font-family-roobert-pro);
  font-variant-numeric: lining-nums tabular-nums;
}
