@import 'src/common/styles/settings.module.css';

.panel {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  padding: var(--size-12) var(--gutter) 0;

  > * {
    padding-bottom: var(--size-20);
  }

  > *:first-child {
    padding-bottom: var(--size-12);
  }

  > *:nth-child(2) {
    padding-bottom: var(--size-28);
  }

  > *:nth-child(3) {
    padding-bottom: 0;
  }

  @media (--breakpoint-desktop) {
    max-width: calc(var(--6-cols) + 2 * var(--gutter));

    border: 1px solid var(--rebranding-md-color-grey-10);
    border-bottom: none;
  }
}

.date {
  padding-bottom: var(--size-12);
}

.reference {
  overflow-wrap: break-word;
}

.strong {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}
