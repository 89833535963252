@import 'src/common/styles/settings.module.css';

.row {
  cursor: pointer;
  &:not(:last-child) td {
    border-bottom: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.desktop {
  display: none;

  @media (--breakpoint-desktop) {
    display: table-row;
  }
}

.mobile {
  display: table-row;

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.pill {
  position: absolute;
  right: 0;

  @media (--breakpoint-desktop) {
    position: relative;

    min-width: var(--size-40);
    padding: var(--size-4) var(--size-12);

    border-radius: var(--size-100);
    color: var(--color-white);

    font: var(--size-16) var(--font-family-roobert-pro);
    font-weight: var(--font-weight-roobert-pro-semi-bold);
  }
}

.failed,
.failed_and_notified {
  color: var(--rebranding-md-color-red);
  @media (--breakpoint-desktop) {
    background-color: var(--rebranding-md-color-red);
    color: var(--color-white);
  }
}

.pending {
  color: var(--rebranding-md-color-orange);
  @media (--breakpoint-desktop) {
    background-color: var(--rebranding-md-color-orange);
    color: var(--color-white);
  }
}

.success {
  color: var(--rebranding-md-color-green);
  @media (--breakpoint-desktop) {
    background-color: var(--rebranding-md-color-green);
    color: var(--color-white);
  }
}
