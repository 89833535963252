@import 'src/common/styles/settings.module.css';

.root {
  padding: 0 var(--size-20) var(--size-40);

  @media (--breakpoint-desktop) {
    padding: 0 0 var(--size-40);
  }
}

.section {
  padding-top: var(--size-64);
}

.logo {
  max-width: var(--2-cols);
  padding-bottom: var(--size-36);

  @media (--breakpoint-desktop) {
    width: calc(var(--3-cols) + var(--gutter));
    max-width: unset;
    padding-bottom: var(--size-64);
  }
}

.instructions {
  display: grid;
  grid-row-gap: var(--size-64);
  grid-template-columns: auto;
  padding-top: var(--size-20);

  @media (--breakpoint-desktop) {
    grid-column-gap: calc(var(--cell-length) + calc(2 * var(--gutter)));
    grid-template-columns: var(--5-cols) var(--5-cols);
  }
}

.item {
  > *:nth-child(2) {
    padding: var(--size-12) 0 var(--size-20);
  }
}
