@import 'src/common/styles/settings.module.css';

.form > *:first-child {
  padding: var(--size-12) 0 var(--size-20);
}

.formError {
  padding: var(--size-12);

  margin: var(--size-20) calc(-1 * var(--size-20)) calc(-1 * var(--size-12));

  background: var(--rebranding-md-color-red-lightest);

  color: var(--rebranding-md-color-red);

  text-align: center;
}

.buttonWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: 50%;
  }
}
