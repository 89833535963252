@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--size-20);

  text-align: center;

  > *:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  > *:last-child {
    margin-top: var(--size-16);
  }
}
