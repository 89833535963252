@import 'src/common/styles/settings.module.css';

.form {
  display: flex;
  flex-direction: column;
}

.singleColumn {
  width: 100%;

  padding-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    max-width: var(--3-cols);
  }
}

.row {
  display: flex;
  flex-direction: column;
  > *:nth-child(2) {
    margin-top: var(--size-28);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    > *:nth-child(2) {
      max-width: calc(var(--5-cols) + 2 * var(--gutter));
      margin-top: 0;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: auto;
  column-gap: var(--gutter);
  row-gap: var(--size-20);

  padding-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    grid-template-columns: repeat(2, var(--3-cols));
  }
}

.buttonWrapper {
  @media (--breakpoint-desktop) {
    flex-grow: 1;
    max-width: var(--cell-length);
  }
}

.deactivate {
  @media (--breakpoint-desktop) {
    max-width: calc(var(--cell-length) + var(--gutter));
  }
}

.buttonWrapper-2-cols {
  @media (--breakpoint-desktop) {
    flex-grow: 1;
    max-width: var(--2-cols);
  }
}

.buttons {
  display: grid;
  grid-template-columns: auto;
  row-gap: var(--size-20);

  @media (--breakpoint-desktop) {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.passwordHelper {
  padding: var(--size-4);
}
