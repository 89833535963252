@import 'src/common/styles/settings.module.css';

:root {
  --sidebar-width: calc(var(--cell-length) + calc(2 * var(--gutter)));
}

.banner {
  color: var(--white);
}

.logo {
  display: none;

  @media (--breakpoint-desktop) {
    display: block;
  }
}

.announcementText {
  color: var(--white);

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: var(--size-12) var(--gutter);

  @media (--breakpoint-desktop) {
    padding: var(--size-12) calc(2 * var(--gutter) + var(--cell-length))
      var(--size-12)
      calc(var(--sidebar-width) + var(--gutter) + var(--cell-length));
  }

  background-color: #272f3c;
}

.prod {
  margin-top: var(--size-52);

  @media (--breakpoint-desktop) {
    margin-top: 0;
  }
}

.announcement {
  display: flex;
  align-items: center;
}

.cta {
  display: flex;
}

.close {
  align-self: center;
  margin-left: var(--size-8);

  cursor: pointer;
}
