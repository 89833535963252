@import 'src/common/styles/settings.module.css';

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  > * {
    flex: 1;
  }
}

.subtitle {
  margin: var(--size-28) 0 var(--size-8);
}

.buttonLink {
  width: 100%;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  @media (--breakpoint-desktop) {
    justify-content: flex-end;
  }
}

.buttonWrapper {
  width: 130px;

  @media (--breakpoint-desktop) {
    padding-left: var(--size-20);
  }
}

.successFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-end;
}

.successButton {
  width: 100%;
  @media (--breakpoint-desktop) {
    width: 80px;
  }
}
