@import 'src/common/styles/settings.module.css';

.tableContainer {
  max-height: 50vh;
  overflow-y: auto;
}

.headingWrapper {
  margin-top: var(--gutter);
  margin-left: var(--gutter);
}

.table {
  width: 100%;
  margin: var(--size-20) 0;

  border: 1px solid var(--rebranding-md-color-grey-10);
}

.row:not(:last-child) td {
  border-bottom: 1px solid var(--rebranding-md-color-grey-10);
}

.desktopColumns {
  display: none;

  @media (--breakpoint-desktop) {
    display: table-row;
  }
}

.mobileColumns {
  display: inherit;

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.cell-content {
  position: relative;

  display: flex;
  flex-direction: column;

  margin: var(--size-12) var(--gutter);

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.cell {
  background-color: var(--color-white);
}

.period {
  font-weight: var(--font-weight-roobert-pro-semi-bold);

  &:hover {
    background-color: var(--rebranding-md-color-brand-purple);
    color: var(--color-white);
  }
}

.link-icon {
  position: absolute;
  top: -7px;
  right: 0;
}

.link {
  margin-right: var(--size-8);

  color: var(--rebranding-md-color-brand-purple);

  text-decoration: none;
}

.header {
  display: none;

  background-color: var(--rebranding-md-color-brand-purple-lightest);

  @media (--breakpoint-desktop) {
    display: revert;
  }
}

.bold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.right {
  justify-content: flex-end;

  text-align: right;

  vertical-align: middle;
}

.unsettled {
  color: var(--rebranding-md-color-grey-50);
}

.transferred {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobilePeriod {
  padding-bottom: var(--size-4);
}

.mobileDocuments {
  display: flex;
  padding-top: var(--size-20);

  > *:first-child {
    padding-right: var(--size-20);
  }
}

.mobileDocumentColumn {
  > *:first-child {
    padding-bottom: var(--size-4);
  }
}

.emptyHistory {
  padding: 0 var(--size-20);
  margin-top: var(--size-20);
  margin-bottom: var(--size-36);

  text-align: center;

  @media (--breakpoint-desktop) {
    padding: var(--size-52) 0;

    border-top: 1px solid var(--rebranding-md-color-grey-10);
  }
}
