@import 'src/common/styles/settings.module.css';

.root {
  padding: var(--size-20);

  background-color: var(--color-light-grey);

  font: var(--size-14) var(--font-family-ibmplexmono);

  pre {
    display: block;

    line-break: anywhere;

    white-space: pre-wrap;
  }
}
