@import 'src/common/styles/settings.module.css';

.root {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;

  width: 100vw;

  padding: var(--size-32);

  margin-top: var(--size-52);
  margin-bottom: var(--size-80);

  text-align: center;

  @media (--breakpoint-desktop) {
    padding: 0;

    margin-top: var(--size-168);
  }
}

.logoWrapper {
  margin-bottom: var(--size-48);
}

.iconWrapper {
  width: 144px;
  margin: var(--size-48);
}

.resendMessage {
  margin-top: var(--size-28);
}

.link {
  color: var(--rebranding-md-color-brand-purple);

  cursor: pointer;

  text-decoration: none;

  &:hover {
    @mixin outline;
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}

.strong {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}
