.root {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;

  @media print {
    display: block !important;
  }
}
