@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  padding: 10px 0;

  margin-left: var(--size-20);

  background: transparent;
  border: 0;
  color: var(--rebranding-md-color-brand-purple-light);
  cursor: pointer;

  font-size: var(--size-16);
  line-height: var(--size-20);
  text-decoration: none;

  &--small {
    font-size: var(--size-12);
    line-height: var(--size-16);
  }

  .default {
    display: block;
  }

  .white {
    display: none;
  }

  &:hover,
  &:focus,
  &.active {
    color: var(--rebranding-color-white);
    opacity: 1;

    .default {
      display: none;
    }

    .white {
      display: block;
    }
  }

  li {
    list-style: none;
  }

  @media (--breakpoint-desktop) {
    flex-direction: column;
    padding: var(--size-16) 0;
    margin-left: 0;

    &.active {
      padding-right: var(--size-4);

      border-left: var(--size-4) solid var(--rebranding-color-white);
      pointer-events: none;
    }
  }
}

.iconWrapper {
  display: flex;
  width: var(--size-36);

  margin-bottom: var(--size-4);

  &--small {
    width: var(--size-24);
    margin-bottom: var(--size-4);
  }
}

.label {
  padding-left: var(--size-8);

  margin-bottom: var(--size-8);

  text-align: center;

  &--small {
    padding-left: var(--size-4);
    margin-bottom: var(--size-4);
  }

  @media (--breakpoint-desktop) {
    padding-left: 0;

    margin-bottom: 0;

    text-align: inherit;
  }
}
