@import 'src/common/styles/settings.module.css';

.root {
  align-self: center;

  padding: var(--size-4) var(--size-12);

  background: var(--color-white);
  border-radius: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  color: var(--color-elrond-blue);

  cursor: pointer;

  font-size: var(--size-14);

  text-decoration: none;

  &:hover {
    background-color: var(--rebranding-md-color-grey-10);
  }

  &:active {
    box-shadow: none;
  }
}
