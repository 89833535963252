@import 'src/common/styles/settings.module.css';

.root {
  width: 100vw;

  padding: var(--size-20);

  @media (--breakpoint-desktop) {
    width: calc(var(--8-cols) + var(--gutter));
    padding: var(--size-12) var(--size-20) var(--size-20) var(--size-20);
    margin-bottom: var(--size-28);

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}
