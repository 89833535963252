@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 1 auto;
  width: 100vw;
  height: fit-content;
  padding: var(--size-12) var(--gutter) var(--size-20);

  border-bottom: 1px solid var(--rebranding-md-color-grey-10);

  @media (--breakpoint-desktop) {
    max-width: calc(var(--6-cols) + var(--gutter) * 2);

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}
