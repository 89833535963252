@import 'src/common/styles/settings.module.css';

.topRow {
  display: none;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  margin-bottom: var(--size-28);

  @media (--breakpoint-desktop) {
    display: flex;
  }
}

.closeButton {
  cursor: pointer;
}

.okButton {
  display: inline;

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.iconWrapper {
  width: calc(var(--size-88) + var(--size-4));
  margin: 0 auto var(--size-20);
}

.content {
  margin-bottom: var(--size-32);

  text-align: center;

  @media (--breakpoint-desktop) {
    width: calc(var(--6-cols) + 2 * var(--gutter));
  }
}

.bottomText {
  margin-top: var(--size-36);
}

.links {
  a {
    color: var(--rebranding-md-color-brand-purple);

    text-decoration: none;
  }

  a:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}
