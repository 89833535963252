@import 'src/common/styles/settings.module.css';

.root {
  padding: 0;

  background: none;
  border: none;
  cursor: pointer;
  outline: 0;

  &:focus {
    @mixin outline;
  }

  svg path {
    fill: var(--rebranding-md-color-brand-purple);
  }
}
