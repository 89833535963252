@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  padding: var(--size-20);

  @media (--breakpoint-desktop) {
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--breakpoint-desktop) {
    margin-top: var(--size-168);
  }
}

.message {
  margin-bottom: var(--size-20);

  text-align: center;
}
