@import 'src/common/styles/settings.module.css';

.root {
  position: relative;

  width: 100%;
  min-width: 130px;
  margin-right: var(--size-20);
}

.input {
  width: 100%;
  padding-bottom: 7px;
  padding-left: var(--size-32);
  resize: none;

  border: none;

  border-bottom: 1px solid var(--rebranding-md-color-grey-70);

  border-radius: 0;

  color: var(--rebranding-md-color-brand-black);

  font-family: var(--font-family-roobert-pro);

  font-size: var(--size-16);
  line-height: var(--size-20);

  &::placeholder {
    color: var(--rebranding-md-color-grey-70);
  }
}

.input:disabled {
  background: white;
  border-color: var(--rebranding-md-color-grey-30);
  color: var(--rebranding-md-color-grey-50);
  cursor: not-allowed;

  &::placeholder {
    color: var(--rebranding-md-color-grey-50);
  }

  + .searchIcon {
    svg path {
      fill: var(--rebranding-md-color-grey-50);
    }
  }
}

.input:focus {
  border-color: var(--rebranding-md-color-brand-purple);
  color: var(--rebranding-md-color-brand-purple);
  outline: none;

  + .searchIcon {
    svg path {
      fill: var(--rebranding-md-color-brand-purple);
    }
  }
}

.searchIcon {
  position: absolute;
  bottom: 7px;
  left: 0;

  pointer-events: none;

  svg path {
    fill: var(--rebranding-md-color-grey-70);
  }
}
