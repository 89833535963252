@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 1 auto;
  padding: var(--size-12) var(--gutter) var(--size-20);

  margin-top: var(--size-36);

  @media (--breakpoint-desktop) {
    max-width: calc(var(--6-cols) + var(--gutter) * 2);

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.store {
  padding-top: var(--size-12);

  > * {
    padding-bottom: var(--size-12);
  }
}

.company {
  padding-top: var(--size-12);

  > *:not(:first-child) {
    padding-top: var(--size-20);
  }

  > *:last-child {
    padding-bottom: var(--size-20);
  }
}

.separator {
  padding-bottom: var(--size-12);
}

.documents {
  padding-top: var(--size-12);
}

.downloadButton {
  display: inline-flex;

  gap: var(--size-4);

  text-decoration: none;
}
