.wrapper {
  display: flex;

  align-items: center;
  justify-content: center;
  width: var(--size-16);
  height: var(--size-16);
  margin-left: var(--size-4);

  background: var(--rebranding-md-color-green);
  border-radius: 50%;
}
