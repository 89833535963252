@import 'src/common/styles/settings.module.css';

.root {
  display: none;

  > * {
    margin-left: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    display: flex;

    height: fit-content;

    margin: auto 0 var(--size-8) auto;
  }
}
