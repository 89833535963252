@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;

  font-family: var(--font-family-roobert-pro);
}

.inputWrapper {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
}

.full {
  width: 100%;

  input {
    width: 100%;
  }
}

.visuallyHidden {
  @mixin visuallyHidden;
}
