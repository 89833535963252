@import 'src/common/styles/settings.module.css';

.root {
  @media (--breakpoint-desktop) {
    max-width: calc(var(--6-cols) + 2 * var(--gutter));
  }

  > *:first-child {
    padding-bottom: var(--size-20);
  }
}

.panel {
  padding: var(--size-12) var(--gutter) 0;

  @media (--breakpoint-desktop) {
    border: 1px solid var(--rebranding-md-color-grey-10);

    border-bottom: none;
  }
}

.cancelWrapper {
  display: none;
  justify-content: flex-end;

  padding: var(--size-20);

  @media (--breakpoint-desktop) {
    display: flex;
  }
}

.mobileButtons {
  display: flex;
  flex-direction: column;

  padding: var(--size-20);
  margin-top: var(--size-16);

  > *:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.errorMessage {
  display: flex;
  justify-content: center;

  margin-bottom: var(--size-20);

  color: var(--rebranding-md-color-red);

  @media (--breakpoint-desktop) {
    justify-content: flex-end;
    padding: 0 var(--size-16);
    margin-top: var(--size-16);
  }
}

.details {
  display: flex;
  flex-direction: column;

  padding: var(--size-28) 0 var(--size-20) 0;

  > div {
    margin-bottom: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    flex-wrap: wrap;

    padding: var(--size-28) 0 0 0;

    > div {
      flex-grow: 1;
      width: 50%;
      height: calc(var(--size-40) + var(--size-20));

      margin-bottom: 0;
    }
  }
}

.strong {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.status {
  padding-top: var(--size-12);
}

.separator {
  margin: var(--size-20) 0;
}
