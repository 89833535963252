@import 'src/common/styles/settings.module.css';

.root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-100) var(--size-20);

  text-align: center;

  > * {
    margin-top: var(--size-12);
  }
}

.errorCode {
  margin-top: var(--size-80);

  color: var(--rebranding-md-color-brand-purple);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-84);

  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-100);

  @media (--breakpoint-desktop) {
    margin-top: var(--size-160);
  }
}
