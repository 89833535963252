@import 'src/common/styles/settings.module.css';

.root {
  width: 100vw;

  padding: 0 var(--size-20) var(--size-36) var(--size-20);

  margin: var(--size-28) 0 var(--size-200) 0;

  @media (--breakpoint-desktop) {
    width: calc(var(--6-cols) + 2 * var(--gutter));

    padding: var(--size-12) var(--size-20) var(--size-20) var(--size-20);

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: var(--size-20);
}

.fullRow {
  margin-top: var(--size-20);
}

.customerEmail {
  margin-bottom: var(--size-12);
}

.captionWrapper {
  margin-top: var(--size-4);
}

.half {
  > div {
    margin-bottom: var(--size-8);
  }

  input {
    width: calc(100vw - var(--size-40));
  }

  @media (--breakpoint-desktop) {
    input {
      width: var(--3-cols);
    }

    > div {
      margin-bottom: 0;
    }
  }
}

.third {
  > div {
    margin-bottom: var(--size-8);
  }

  input {
    width: calc(100vw - var(--size-40));
  }

  @media (--breakpoint-desktop) {
    input {
      width: var(--2-cols);
    }

    > div {
      margin-bottom: 0;
    }
  }
}

.formFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.buttonWrapper {
  width: 100%;

  margin-top: var(--size-28);

  @media (--breakpoint-desktop) {
    width: var(--size-160);

    margin-top: var(--size-36);
  }
}

.errorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: var(--size-8);

  width: 100%;

  margin-top: var(--size-20);

  color: var(--rebranding-md-color-red);

  @media (--breakpoint-desktop) {
    flex-direction: row;

    width: unset;

    margin-top: var(--size-36);
  }
}

.link {
  color: var(--rebranding-md-color-brand-purple);

  text-decoration: none;

  &:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}

.dropdownWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: 50%;

    padding-left: var(--size-8);
  }
}

.fieldWithCaption {
  margin-bottom: var(--size-8);

  span {
    margin-top: var(--size-4);
  }

  @media (--breakpoint-desktop) {
    margin-bottom: 0;
  }
}

.description {
  margin-top: var(--size-4);
}
