@import 'src/common/styles/settings.module.css';

.errorMessage {
  display: flex;
  align-items: center;
  margin-top: var(--size-4);

  color: var(--rebranding-md-color-red);

  font-size: var(--size-14);
  line-height: var(--size-16);
}
