@import 'src/common/styles/settings.module.css';

.root {
  position: relative;
  z-index: 0;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 0 var(--size-20) 0;

  background: var(--color-white);
}

.container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: calc(var(--11-cols) + var(--3-cols));
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: var(--size-20) 0 var(--size-52);
  margin-bottom: var(--size-36);

  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 16px 28px rgba(47, 52, 124, 0.2);
}

.contentFilter {
  width: 100%;

  /* min-height: 300px; Check if this should be uncommented after invoice exporting is implemented */

  padding: 0 var(--size-14);

  @media (--breakpoint-desktop) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    width: 100%;

    min-height: unset;

    margin-bottom: var(--size-20);
  }
}

.rows {
  margin-top: var(--size-20);
}

.header {
  content: '';

  margin: var(--size-36) var(--gutter);
}

.tabsWrapper {
  position: relative;

  flex: 0 0 auto;

  display: flex;
  flex-direction: column;

  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-right: var(--size-14);
  }
}

.newInvoiceMobile {
  margin: var(--size-20) var(--size-14) var(--size-14);

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.newInvoiceDesktop {
  display: none;

  @media (--breakpoint-desktop) {
    align-self: flex-end;
    display: flex;
    width: calc(var(--size-124) + var(--size-24));
  }
}

.filterTogglerTabs {
  position: absolute;

  top: 50%;

  right: var(--size-14);

  display: flex;
  align-items: center;

  width: var(--size-28);

  &:focus {
    outline: none;
  }

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.hiddenMobile {
  display: none;

  @media (--breakpoint-desktop) {
    display: flex;
  }
}
