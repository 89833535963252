@import 'src/common/styles/settings.module.css';

.row {
  align-self: flex-end;

  display: flex;
  flex-direction: row;
}

.filterContainer {
  width: 100%;
  margin-bottom: var(--size-28);

  line-height: 1;

  @media (--breakpoint-desktop) {
    width: calc(var(--cell-length) + var(--gutter) * 4 + var(--size-4));
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-right: var(--size-20);
    }
  }
}

.searchInputContainer {
  width: 100%;
  margin-bottom: var(--size-28);

  line-height: 1;

  @media (--breakpoint-desktop) {
    width: calc(var(--cell-length) + var(--gutter) * 11 + var(--size-4));
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0;
  }
}
