@import 'src/common/styles/settings.module.css';

.auto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xSmall {
  width: 10px;
  height: 10px;
}

.small {
  width: var(--size-16);
  height: var(--size-16);
}

.medium {
  width: var(--size-20);
  height: var(--size-20);
}

.large {
  width: var(--size-36);
  height: var(--size-36);
}

.xLarge {
  width: var(--size-52);
  height: var(--size-52);
}
