@import 'src/common/styles/settings.module.css';

:root {
  --half-cell: calc(0.5 * var(--cell-length));
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-12);
  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-16);
}

.griddleTableWrapper {
  flex: 1 0 auto;
  display: flex;

  max-width: 100vw;

  &.empty {
    flex-direction: column;
  }
}

.table {
  width: 100%;

  border-bottom: 1px solid var(--rebranding-md-color-grey-10);

  @media (--breakpoint-desktop) {
    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.tableHeading {
  display: none;

  padding-right: var(--size-12);
  padding-left: var(--size-12);

  background: var(--rebranding-md-color-brand-purple-lightest);

  @media (--breakpoint-desktop) {
    display: table-header-group;
  }
}

.tableHeadingCell {
  width: var(--size-88);
  padding: var(--size-8) 0 var(--size-8) 0;

  color: var(--rebranding-md-color-brand-black);

  line-height: var(--size-20);
  text-align: left;
}

.row {
  max-height: var(--size-64);

  border-top: 1px solid var(--rebranding-md-color-grey-10);

  cursor: pointer;

  @media (--breakpoint-desktop) {
    max-height: unset;

    &:first-child .cell {
      position: relative;

      padding-top: var(--size-20);

      &::after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: calc(var(--size-20) - var(--size-8));

        background: var(--color-white);
      }
    }
  }
}

.cell {
  box-sizing: content-box;

  max-height: 64px;

  padding: var(--size-12) var(--size-20);

  overflow: hidden;

  border-top: 1px solid var(--rebranding-md-color-grey-10);

  color: var(--rebranding-md-color-brand-black);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);

  line-height: var(--size-20);

  overflow-wrap: break-word;

  &:first-child {
    padding-right: 0;
  }

  &:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
  }

  @media (--breakpoint-desktop) {
    max-height: unset;

    padding: var(--size-12) calc(var(--size-20) / 2);

    &:first-child {
      padding-left: var(--size-20);
    }

    &:nth-child(2) {
      display: table-cell;
    }

    &:nth-child(4) {
      text-align: right;
    }

    &:nth-child(5) {
      text-align: right;
    }

    &:nth-child(6) {
      text-align: right;
    }

    &:last-child {
      padding-right: var(--size-20);
    }
  }
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: calc(var(--size-12) * 2);
  padding: var(--size-4) 0;

  &.paid {
    color: var(--color-paid);
  }

  &.pending {
    color: var(--rebranding-md-color-blue);
  }

  &.cancelled,
  &.canceled,
  &.expired {
    color: var(--color-wild-blue-yonder);
  }

  &.refunded {
    color: var(--color-refunded);
  }

  &.refunding {
    color: var(--rebranding-md-color-orange);
  }

  &.resent {
    color: var(--color-resent);
  }

  &.dispute {
    color: var(--color-dispute);
  }

  &.claim {
    color: var(--color-claim);
  }
}

.noAction {
  color: var(--color-indigo);

  font-size: var(--size-16);
  letter-spacing: 2px;
  line-height: var(--size-20);
  text-align: center;
}

.pageLink {
  width: 20px;
  height: 24px;

  padding: 0;

  background: none;
  border: 0px solid var(--color-minsk);
  border-radius: 4px;
  color: var(--color-indigo);
  cursor: pointer;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-12);
  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-16);

  transform-origin: center center;
}

.pageLink:hover {
  border: 1px solid var(--rebranding-md-color-brand-purple);
}

.pageLink.current {
  background: var(--rebranding-md-color-brand-purple);
  color: var(--color-white);

  cursor: not-allowed;
}

.pageLink:disabled {
  cursor: not-allowed;
}

.pageLink:not(:last-child) {
  margin-right: 20px;
}

.page {
  text-transform: uppercase;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 100vw;

  margin-top: var(--size-14);
  margin-bottom: var(--size-20);

  font: var(--size-16) var(--font-family-roobert-pro);
}

.previous,
.next {
  box-sizing: border-box;

  padding: 7px;

  border: 1px solid var(--rebranding-md-color-brand-black);

  border-radius: 2px;

  cursor: pointer;
}

.pagination_disabled {
  cursor: not-allowed;
}

.pages {
  margin: 0 var(--size-36);
}

.pageNumber {
  padding: 6px;

  color: var(--rebranding-md-color-brand-black);

  cursor: pointer;
}

.currentPage {
  color: var(--rebranding-md-color-brand-purple);
  cursor: not-allowed;

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.previous {
  margin-right: 6px;
}

.next {
  margin-left: 6px;
}

.date {
  white-space: nowrap;

  span {
    color: var(--rebranding-md-color-grey-60);

    font-size: var(--size-14);
  }
}

.date-day {
  margin-right: var(--size-8);

  font-weight: var(--font-weight-roobert-pro-bold);
}

.noResults {
  padding: 0 var(--size-20);
}

.amount {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.reference {
  max-width: var(--3-cols);
  padding-left: var(--size-12);
}

.detailsReference {
  text-transform: uppercase;
}

.tableAppear {
  opacity: 0;
}

.tableDesktop {
  position: relative;

  display: none;
  width: 100%;

  @media (--breakpoint-desktop) {
    display: unset;
  }
}

.tableMobile {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--breakpoint-desktop) {
    display: none;
  }
}

.currencyMobile {
  font-size: var(--size-14);
  text-align: right;
}

.referenceDate {
  display: flex;
  flex-direction: column;
  max-width: var(--2-cols);
}

.statusAmount {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: var(--size-80);
}
