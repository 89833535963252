:root {
  --white: #ffffff;
  --color-white: #ffffff;
  --color-titan-white: #f5f5ff;
  --color-soft-blue: #f0f4fd;
  --color-gray-blue: #e2e9f7;
  --color-mountain-meadow: #18c768;
  --color-minsk: #2f347c;
  --color-indigo: #5573c2;
  --color-light-grey: #f4f4f5;
  --color-red: #e86868;
  --color-brand: #7a42ff;
  --color-paid: #18c768;
  --color-refunded: #e956ae;
  --color-refunding: #e956ae;
  --color-dispute: #f19305;
  --color-resent: #2a9ee2;
  --color-pending: #7c81b0;
  --color-canceled: #7c81b0;
  --color-expired: #ff595e;
  --color-wild-blue-yonder: #7c81b0;
  --color-claim: #076d78;
  --color-dodger-blue: #4275fa;
  --color-royal-blue: #5840ec;
  --color-elrond-blue: #133aab;
  --rebranding-color-blue-aqua: #34f6f2;
  --rebranding-color-blue-light: #f1faff;
  --rebranding-color-green: #35c668;
  --rebranding-color-green-light: #94f0b4;
  --rebranding-color-red: #ff4848;
  --rebranding-color-yellow: #ffcd4c;
  --rebranding-color-black: #252d3d;
  --rebranding-color-grey: #a9acb5;
  --rebranding-color-grey-light: #e9edf0;
  --rebranding-color-white: #ffffff;
  --rebranding-md-color-brand-black: #252d3d;
  --rebranding-md-color-brand-purple: #7c4dff;
  --rebranding-md-color-brand-purple-lightest: #ece5ff;
  --rebranding-md-color-brand-purple-light: #cebdff;
  --rebranding-md-color-brand-purple-dark: #412297;
  --rebranding-md-color-brand-purple-darkest: #2d1861;
  --rebranding-md-color-blue: #4794ff;
  --rebranding-md-color-blue-lightest: #daeaff;
  --rebranding-md-color-blue-light: #a3c9ff;
  --rebranding-md-color-blue-dark: #3976cc;
  --rebranding-md-color-blue-darkest: #2b5999;
  --rebranding-md-color-green: #10ab46;
  --rebranding-md-color-green-lightest: #cfeeda;
  --rebranding-md-color-green-light: #87d5a3;
  --rebranding-md-color-green-dark: #0d8938;
  --rebranding-md-color-green-darkest: #0a672a;
  --rebranding-md-color-orange: #eb7100;
  --rebranding-md-color-orange-lightest: #fbe3cc;
  --rebranding-md-color-orange-light: #f5b880;
  --rebranding-md-color-orange-dark: #bc5a00;
  --rebranding-md-color-orange-darkest: #8d4400;
  --rebranding-md-color-red: #cd2553;
  --rebranding-md-color-red-lightest: #f5d3dd;
  --rebranding-md-color-red-light: #e692a9;
  --rebranding-md-color-red-dark: #a41e42;
  --rebranding-md-color-red-darkest: #7b1632;
  --rebranding-md-color-pink: #ec6798;
  --rebranding-md-color-pink-lightest: #fbe1ea;
  --rebranding-md-color-pink-light: #f6b3cc;
  --rebranding-md-color-pink-dark: #bd527a;
  --rebranding-md-color-pink-darkest: #8e3e5b;
  --rebranding-md-color-grey-10: #e9eaec;
  --rebranding-md-color-grey-20: #d3d5d8;
  --rebranding-md-color-grey-30: #bec0c5;
  --rebranding-md-color-grey-40: #a8abb1;
  --rebranding-md-color-grey-50: #92969e;
  --rebranding-md-color-grey-60: #7c818b;
  --rebranding-md-color-grey-70: #666c77;
  --rebranding-md-color-grey-80: #515764;
  --rebranding-md-color-grey-90: #3b4250;
}
