@import 'src/common/styles/settings.module.css';

.modalFooter {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-top: var(--size-28);

  > *:last-child {
    margin-bottom: var(--size-12);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: flex-end;

    > *:last-child {
      margin-bottom: 0;
    }

    *:not(:last-child) {
      margin-right: var(--size-20);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  justify-content: flex-start;
  width: 100%;
}
