@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;

  > *:last-child {
    padding-bottom: var(--size-20);
  }
}

.row {
  display: flex;
  flex-direction: column;

  > * {
    padding-bottom: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    flex-wrap: wrap;

    > *:first-child {
      padding-right: var(--cell-length);
    }
  }
}
