@import 'src/common/styles/settings.module.css';

:root {
  --caret-size: var(--size-40);
}

.root {
  position: relative;

  width: 100%;
}

.input {
  position: relative;

  width: 100%;
  padding: var(--size-8) var(--caret-size) var(--size-8) var(--size-12);

  background: var(--color-white);

  border: 1px solid var(--rebranding-md-color-grey-70);

  border-radius: 2px;

  color: var(--rebranding-color-black);

  cursor: pointer;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-variant-numeric: lining-nums tabular-nums;
  line-height: var(--size-20);

  text-align: left;

  &:focus {
    @mixin outline;
  }

  &::placeholder {
    color: var(--rebranding-md-color-grey-80);
  }

  &.error {
    border-color: var(--rebranding-md-color-red);
    color: var(--rebranding-md-color-red);

    &::placeholder {
      color: var(--rebranding-md-color-red);
    }
  }
}

.open.input {
  border-color: var(--rebranding-md-color-brand-purple);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &.error {
    &::placeholder {
      color: var(--rebranding-md-color-grey-80);
    }
  }
}
