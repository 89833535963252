@import 'src/common/styles/settings.module.css';

.divider {
  margin-top: var(--size-36);
  margin-bottom: var(--size-20);

  border: 0;
  border-top: 1px solid var(--rebranding-md-color-grey-10);
}

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    flex: 1;
  }
}
.bold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: var(--size-84);
  }
}

.exchangeWarning {
  margin-top: var(--size-4);
}

.businessDescription {
  margin-top: var(--size-20);
}

.sectorWrapper {
  margin-top: var(--size-20);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
