@import 'src/common/styles/settings.module.css';

.root {
  display: inline-block;
  margin-bottom: var(--size-4);

  color: var(--rebranding-md-color-brand-black);

  font-family: var(--font-family-roobert-pro);
}

.optional {
  font-size: var(--size-14);
  line-height: var(--size-16);
}

.main {
  font-size: var(--size-16);
  line-height: var(--size-20);
}

.disabled {
  color: var(--rebranding-md-color-grey-60);
}
