@import 'src/common/styles/settings.module.css';

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  > * {
    flex: 1;
  }
}

.logoWrapper {
  display: flex;

  width: var(--size-merchant-logo-mobile);
  margin-bottom: var(--size-28);

  @media (--breakpoint-desktop) {
    width: var(--size-merchant-logo);

    padding-left: var(--size-20);
  }
}

.authPage {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  font-family: var(--font-family-roobert-pro);

  @media (--breakpoint-desktop) {
    padding-top: var(--size-48);
  }
}

.authContainer {
  width: 100vw;

  padding: 2em;

  margin-bottom: 5em;

  @media (--breakpoint-desktop) {
    width: calc(var(--4-cols) + calc(2 * var(--gutter)));
    padding: 0 0 5em 0;
    margin: auto 0;
  }
}

.authForm {
  padding: var(--size-20) 0;

  @media (--breakpoint-desktop) {
    padding: var(--size-20);

    border: 1px solid var(--rebranding-md-color-grey-10);
  }
}

.formError {
  padding: var(--size-12);

  margin-top: -2px;

  background: var(--rebranding-md-color-red-lightest);

  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  color: var(--rebranding-md-color-red);

  text-align: center;
}
