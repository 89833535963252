@import 'src/common/styles/settings.module.css';

.header {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  > * {
    flex: 1;
  }
}

.footer {
  display: flex;
  flex-direction: column;

  margin-top: var(--size-28);

  text-align: center;

  span {
    color: var(--rebranding-md-color-brand-black);
  }

  @media (--breakpoint-desktop) {
    display: inherit;
  }
}

.formFooter,
.footer {
  a {
    color: var(--rebranding-md-color-brand-purple);

    text-decoration: none;
  }

  a:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}

.formFooter {
  display: flex;
  flex-direction: column-reverse;

  /* first child is forgot password as we are inverting */
  > *:first-child {
    align-self: center;
    padding-top: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;

    > *:first-child {
      align-self: unset;
      padding-top: 0;
    }
  }
}
