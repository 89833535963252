@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  padding: 0 var(--size-20) var(--size-36) var(--size-20);

  margin-top: var(--size-120);

  @media (--breakpoint-desktop) {
    width: var(--6-cols);
    height: unset;

    padding: 0;

    margin-top: 0;
  }

  > *:first-child {
    margin-bottom: var(--size-12);
  }
}

.footer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-top: var(--size-20);

  @media (--breakpoint-desktop) {
    flex-direction: row;
  }
}
