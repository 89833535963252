@import 'src/common/styles/settings.module.css';

:root {
  --sidebar-width: calc(var(--cell-length) + calc(2 * var(--gutter)));
}

.layout {
  position: relative;

  flex: 1 1 0;
  display: flex;
  overflow-y: auto;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100vw;
  height: var(--size-52);

  @media (--breakpoint-desktop) {
    width: var(--sidebar-width);
    height: 100%;
    overflow-y: auto;
  }
}

.content {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding: var(--size-28) 0 var(--size-28) 0;

  @media (--breakpoint-desktop) {
    width: calc(var(--11-cols) + 2 * var(--gutter));
    padding-left: var(--sidebar-width);
  }
}

.content-production {
  padding-top: calc(var(--size-28) + var(--size-52));

  @media (--breakpoint-desktop) {
    padding-top: var(--size-28);
  }
}

.header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 var(--gutter);

  @media (--breakpoint-desktop) {
    flex-direction: row;

    width: calc(var(--11-cols) + 2 * var(--gutter));
  }
}

.sideAction {
  display: flex;
  padding-top: var(--size-8);
  padding-right: var(--gutter);

  @media (--breakpoint-desktop) {
    flex-direction: row-reverse;
    width: var(--cell-length);
    padding-top: var(--size-28);
  }
}

.sideContent {
  @media (--breakpoint-desktop) {
    flex: 1;
    display: flex;
  }
}

.headerContent {
  display: flex;
  flex-direction: column;
}

.storeInfoWrapper {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.storeName {
  display: none;

  @media (--breakpoint-desktop) {
    display: flex;
  }
}

.accountInfo {
  display: flex;
  align-items: center;
  gap: var(--size-4);
}

.accountBadge {
  padding: var(--size-4) var(--size-8);

  background-color: var(--rebranding-md-color-orange);

  border-radius: 500px;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  height: fit-content;

  @media (--breakpoint-desktop) {
    height: unset;

    padding: 0 var(--cell-length);
  }
}
