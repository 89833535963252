@import 'src/common/styles/settings.module.css';

:root {
  --size: var(--size-40);
}

.icon {
  @mixin resetButtonStyles;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: 100%;

  font-family: inherit;

  svg {
    transition: all 0.3s ease-in-out;
  }
}
