@font-face {
  font-family: 'Roobert PRO';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./RoobertPRO-Regular.woff2') format('woff2'),
    url('./RoobertPRO-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roobert PRO';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./RoobertPRO-SemiBold.woff2') format('woff2'),
    url('./RoobertPRO-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Roobert PRO';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./RoobertPRO-Bold.woff2') format('woff2'),
    url('./RoobertPRO-Bold.woff') format('woff');
}

@font-face {
  font-family: 'ibmplexmono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('./IBMPlexMono-Regular.woff2') format('woff2'),
    url('./IBMPlexMono-Regular.woff') format('woff');
}
