@import 'src/common/styles/settings.module.css';

.root {
  position: relative;

  display: inline-block;
  min-width: var(--cell-length);
  padding: var(--size-8) var(--size-12);

  border: 0;
  border-radius: 2px;
  cursor: pointer;
  outline: 0;

  font: var(--size-16) / var(--size-20) var(--font-family-roobert-pro);
  text-align: center;
  text-decoration: none;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 4px;
  }

  &:not(.disabled):not(.loading) {
    &:focus {
      @mixin outline;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.root.primary {
  background: var(--rebranding-md-color-brand-purple);
  color: var(--color-white);

  &:not(.disabled):not(.loading) {
    &:hover {
      background: var(--rebranding-md-color-brand-purple-dark);
    }

    &:active {
      background: var(--rebranding-md-color-brand-purple-darkest);
    }
  }

  &.disabled {
    background: var(--rebranding-md-color-grey-20);
    color: var(--rebranding-md-color-grey-50);

    svg path {
      fill: var(--rebranding-md-color-grey-50);
    }
  }
}

.root.secondary {
  background: var(--color-white);
  box-shadow: inset 0px 0px 0px 1px var(--rebranding-md-color-brand-purple);
  color: var(--rebranding-md-color-brand-purple);

  svg path {
    fill: var(--rebranding-md-color-brand-purple);
  }

  &:not(.disabled):not(.loading) {
    &:hover {
      background: var(--rebranding-md-color-brand-purple);
      color: var(--color-white);
      svg path {
        fill: var(--color-white);
      }
    }

    &:active {
      background: var(--rebranding-md-color-brand-purple-darkest);
      box-shadow: inset 0px 0px 0px 1px
        var(--rebranding-md-color-brand-purple-darkest);
      color: var(--color-white);
    }
  }

  &.disabled {
    box-shadow: inset 0px 0px 0px 1px var(--rebranding-md-color-grey-20);
    color: var(--rebranding-md-color-grey-40);

    svg path {
      fill: var(--rebranding-md-color-grey-30);
    }
  }
}

.root.destructive {
  background: var(--rebranding-md-color-red);
  color: var(--color-white);

  &:not(.disabled):not(.loading) {
    &:hover {
      background: var(--rebranding-md-color-red-dark);
    }

    &:active {
      background: var(--rebranding-md-color-red-darkest);
    }
  }

  &.disabled {
    background: var(--rebranding-md-color-grey-20);
    color: var(--rebranding-md-color-grey-50);
  }
}

.root.test {
  background: var(--rebranding-md-color-orange);
  color: var(--color-white);

  &:not(.disabled):not(.loading) {
    &:hover {
      background: var(--rebranding-md-color-orange-dark);
    }

    &:active {
      background: var(--rebranding-md-color-orange-darkest);
    }
  }

  &.disabled {
    background: var(--rebranding-md-color-grey-20);
    color: var(--rebranding-md-color-grey-50);
  }
}

.root.subtle {
  min-width: 0;
  padding: 0;

  background: none;
  color: var(--rebranding-md-color-brand-purple);

  &:not(.disabled):not(.loading) {
    background: none;

    &:hover {
      color: var(--rebranding-md-color-brand-purple-dark);
    }

    &:active {
      color: var(--rebranding-md-color-brand-purple-darkest);
    }
  }

  &.disabled {
    color: var(--rebranding-md-color-grey-50);
  }
}

.root.destructiveSubtle {
  min-width: 0;
  padding: 0;

  background: none;
  color: var(--rebranding-md-color-red);

  &:not(.disabled):not(.loading) {
    background: none;

    &:hover {
      color: var(--rebranding-md-color-red-dark);
    }

    &:active {
      color: var(--rebranding-md-color-red-darkest);
    }
  }

  &.disabled {
    color: var(--rebranding-md-color-grey-50);
  }
}

.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &.reverse {
    flex-direction: row-reverse;

    .icon {
      margin-right: 0;
      margin-left: var(--size-8);
    }
  }
}

.small {
  padding: var(--size-4) var(--size-8);

  font-size: var(--size-12);
  line-height: var(--size-16);
}

.large {
  font-size: var(--size-20);
  line-height: var(--size-28);
}

.full {
  width: 100%;
}

.icon {
  display: flex;
  margin-right: var(--size-8);
}

.children {
  display: flex;
  align-items: center;
  justify-content: center;

  &.hidden {
    display: none;
  }
}
