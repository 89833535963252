@import 'src/common/styles/settings.module.css';

.root {
  padding: 0 var(--size-12) 0;

  margin-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    position: absolute;

    bottom: calc(var(--size-20) + 6px);

    left: 0;

    margin: 0;
  }
}
