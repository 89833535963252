@import 'src/common/styles/settings.module.css';

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-20);
  }

  > * {
    flex: 1;
  }
}

.columns {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: var(--size-20);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;

    > *:not(:first-child) {
      margin-top: 0;
    }
  }
}

.column {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: 50%;

    &:not(:first-child) {
      margin-left: var(--gutter);
    }
  }
}

.termsAndConditionsLink {
  color: var(--rebranding-md-color-brand-purple);

  text-decoration: none;
  &:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}

.formFooter {
  display: flex;
  flex-direction: column-reverse;
  margin-top: var(--size-48);

  text-align: center;

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;

    text-align: inherit;
  }
}

.termsAndConditionsLabel {
  margin-left: var(--size-24);

  cursor: pointer;
}

.buttonWrapper {
  width: 100%;
  padding-bottom: var(--size-20);

  @media (--breakpoint-desktop) {
    width: inherit;
    min-width: 135px;
    padding-bottom: inherit;
  }
}

.checkboxError {
  margin-left: var(--size-24);
}

.passwordHelper {
  padding: var(--size-4);
}
