@import 'src/common/styles/settings.module.css';

.icon-sort {
  display: flex;
  align-items: center;
  margin-left: var(--size-4);
}

.column-header {
  display: flex;
  justify-content: flex-start;
  padding: var(--size-8);
}

.column-active,
.detailsReference,
.detailsCustomerEmail {
}

.totalAmountValue,
.totalAmountCurrency,
.settlementAmountValue {
  display: flex;
  justify-content: flex-end;
}

.createdAt {
  padding-left: var(--size-20);
}

.status {
  display: flex;
  justify-content: flex-end;

  padding-right: var(--size-20);
}
