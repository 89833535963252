@import 'src/common/styles/settings.module.css';

.info {
  display: flex;
  padding-bottom: var(--size-8);

  color: var(--rebranding-md-color-blue);

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  line-height: var(--size-20);

  > *:last-child {
    padding-left: var(--size-8);
  }
}

.strong {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-40);
  }

  > * {
    flex: 1;
  }
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: var(--size-84);
  }
}
