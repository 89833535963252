@import 'src/common/styles/settings.module.css';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.instructions {
  margin-bottom: var(--size-24);

  color: var(--rebranding-md-color-grey-50);
}

.formFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: var(--size-16);
}

.cancelButton {
  padding-left: 0;

  background: none;

  border: none;

  color: var(--rebranding-md-color-brand-purple);

  cursor: pointer;

  font: var(--size-16) / var(--size-20) var(--font-family-roobert-pro);
}

.formError {
  color: var(--rebranding-md-color-red);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}
