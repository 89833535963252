@import 'src/common/styles/settings.module.css';

.label {
  color: var(--rebranding-md-color-brand-black);

  &.required {
    position: relative;

    display: flex;
    align-items: center;

    color: var(--rebranding-md-color-red);

    &::before {
      content: '*';

      position: absolute;
      top: 25%;
      right: 0px;
    }
  }
}

.optional {
  color: var(--rebranding-md-color-grey-60);
}
