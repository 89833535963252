@import 'src/common/styles/settings.module.css';

.root {
  display: grid;
  grid-column-gap: var(--gutter);
  grid-template-columns: auto;
  padding: var(--size-40) 0 var(--size-20);

  @media (--breakpoint-desktop) {
    grid-template-columns: calc(var(--6-cols) + var(--size-40)) var(--4-cols);
  }
}
