@import 'src/common/styles/settings.module.css';

.modalBody {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  padding: 0 var(--size-20) var(--size-36) var(--size-20);

  margin-top: var(--size-80);

  @media (--breakpoint-desktop) {
    width: var(--6-cols);
    height: unset;

    padding: 0;

    margin-top: 0;
  }
}

.invoiceRow {
  margin-top: var(--size-36);

  > :last-child {
    margin-top: var(--size-36);
  }

  @media (--breakpoint-desktop) {
    display: flex;
    flex-direction: row;

    > :last-child {
      margin-top: 0;
    }
  }
}

.title {
  @media (--breakpoint-desktop) {
    margin-bottom: var(--size-12);
  }
}

.column {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-right: var(--size-14);

  div:not(:first-of-type) {
    margin-top: var(--size-20);
  }

  div:first-of-type {
    margin-top: var(--size-12);
  }

  @media (--breakpoint-desktop) {
    width: 50%;

    margin-top: 0;
  }
}

.bold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.modalFooter {
  display: grid;
  grid-row-gap: var(--size-12);
  grid-template-columns: auto;
  margin-top: var(--size-36);
  margin-bottom: var(--size-36);

  @media (--breakpoint-desktop) {
    grid-template-columns: 60% 40%;
  }
}

.buttons {
  flex: 1 1 0;
  display: flex;
  flex-direction: column-reverse;

  > *:last-child {
    margin-bottom: var(--size-12);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: flex-end;
    > *:last-child {
      margin: 0 0 0 var(--size-20);
    }
  }
}
