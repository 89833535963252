@import 'src/common/styles/settings.module.css';

:root {
  --qr-size: 135px;
}

.grid {
  display: grid;
  grid-template-columns: auto;
  padding-top: var(--size-20);

  column-gap: var(--gutter);
  row-gap: var(--size-20);

  @media (--breakpoint-desktop) {
    grid-template-columns: repeat(2, var(--3-cols));
  }
}

.column {
  display: flex;
  flex-direction: column;

  > *:first-child {
    padding-bottom: var(--size-20);
  }
}

.qr-wrapper {
  display: none;
  flex-flow: column;
  align-items: center;
  width: 60%;
  padding-left: var(--gutter);

  @media (--breakpoint-desktop) {
    display: flex;
  }
}

.qrCode {
  display: flex;
  justify-content: center;
  width: var(--qr-size);
  height: var(--qr-size);
}

.descriptionMobile {
  @media (--breakpoint-desktop) {
    display: none;
  }
}

.description {
  display: none;

  @media (--breakpoint-desktop) {
    display: unset;
  }
}
