@import 'src/common/styles/settings.module.css';

:root {
  --checkbox-size: var(--size-16);
}

.root {
  position: relative;

  display: flex;
  padding: 0 var(--size-8) 0 calc(var(--checkbox-size) + var(--size-8));

  color: var(--rebranding-md-color-brand-black);
  cursor: pointer;

  font-family: var(--font-family-roobert-pro);

  font-size: var(--size-16);
  line-height: var(--size-20);

  &.disabled {
    color: var(--rebranding-md-color-grey-30);
    cursor: not-allowed;
  }
}

/* Hide native checkbox */
.hidden {
  @mixin visuallyHidden;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: 2px;
  left: 0;

  width: var(--checkbox-size);
  height: var(--checkbox-size);

  background-color: var(--color-white);
  border: 1px solid var(--rebranding-md-color-grey-70);
  border-radius: 2px;
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
  content: '';

  position: absolute;

  display: none;
}

.root input:focus ~ .checkbox {
  @mixin outline;
}

/* Show the checkbox/indicator when checked */
.root input:checked ~ .checkbox {
  border-color: var(--rebranding-md-color-brand-purple);

  /* Style the checkbox/indicator */
  &:after {
    top: 2px;
    left: 5px;

    display: block;

    width: 5px;
    height: 8px;

    border: solid var(--rebranding-md-color-brand-purple);
    border-width: 0 2px 2px 0;

    transform: rotate(45deg);
  }
}

.root input:indeterminate ~ .checkbox {
  border-color: var(--rebranding-md-color-brand-purple);

  /* Style the indeterminate/indicator */
  &:after {
    top: 2px;
    left: 2px;

    display: block;

    width: 10px;
    height: 10px;

    background-color: var(--rebranding-md-color-brand-purple);
    border-radius: 1px;
  }
}

.root input:disabled {
  & ~ .checkbox {
    border-color: var(--rebranding-md-color-grey-30);
  }

  &:checked ~ .checkbox {
    &:after {
      border-color: var(--rebranding-md-color-grey-30);
    }
  }

  &:indeterminate ~ .checkbox {
    &:after {
      background-color: var(--rebranding-md-color-grey-30);
    }
  }
}
