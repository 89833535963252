@import 'src/common/styles/settings.module.css';

.root {
  padding-bottom: var(--size-36);
}

.headerWithButton {
  display: flex;
  flex-direction: column;

  > *:first-child {
    padding-bottom: var(--size-12);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > *:first-child {
      padding-bottom: 0;
    }
  }
}
