@import 'src/common/styles/settings.module.css';

.root {
  padding: var(--size-20);

  border: 1px solid var(--rebranding-md-color-grey-20);
  border-radius: var(--size-4);

  cursor: pointer;

  &:hover {
    border: 1px solid var(--rebranding-md-color-grey-60);
  }

  * {
    margin-bottom: var(--size-4);
  }
}

.active {
  border: 1px solid var(--rebranding-md-color-brand-purple);

  &:hover {
    border: 1px solid var(--rebranding-md-color-brand-purple);
  }
}

.iconWrapper {
  width: var(--size-20);
  margin-bottom: var(--size-8);
}
