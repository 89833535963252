.root {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;

  width: 100vw;

  padding: var(--size-32);

  margin-top: var(--size-168);
  margin-bottom: var(--size-80);

  text-align: center;

  @media (--breakpoint-desktop) {
    width: calc(var(--6-cols) + calc(6 * var(--gutter)));
    padding: 0;
  }
}

.logoWrapper {
  width: var(--size-merchant-logo-mobile);

  margin-bottom: var(--size-48);

  @media (--breakpoint-desktop) {
    width: var(--size-merchant-logo-mobile);
    width: var(--size-merchant-logo);
  }
}
