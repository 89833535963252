@import 'src/common/styles/settings.module.css';

.textArea {
  width: 100%;
  padding: var(--size-8) var(--size-12);
  resize: none;

  background: var(--rebranding-color-white);
  border: 1px solid var(--rebranding-md-color-grey-70);
  border-radius: 2px;
  color: var(--rebranding-md-color-brand-black);
  outline: 0;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  font-weight: var(--font-weight-regular);
  line-height: var(--size-20);

  &::placeholder {
    color: var(--rebranding-md-color-grey-80);
  }

  &:disabled {
    border-color: var(--rebranding-md-color-grey-20);
    color: var(--rebranding-md-color-grey-60);

    cursor: initial;
    cursor: not-allowed;
  }

  &.filled {
    border-color: var(--rebranding-md-color-grey-70);

    &::placeholder {
      color: var(--rebranding-md-color-brand-black);
    }
  }

  &.error {
    border-color: var(--rebranding-md-color-red);
    color: var(--rebranding-md-color-red);

    &::placeholder {
      color: var(--rebranding-md-color-red);
    }
  }
}

textarea:focus {
  @mixin outline;
  border-color: var(--rebranding-md-color-brand-purple);
  color: var(--rebranding-md-color-brand-black);
}
