@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  font: var(--size-16) var(--font-family-roobert-pro);
}

.pageNumbers {
  margin: 0 var(--size-36);
}

.pageNumber {
  padding: 6px;

  color: var(--rebranding-md-color-brand-black);

  cursor: pointer;
}

.currentPage {
  color: var(--rebranding-md-color-brand-purple);
  cursor: not-allowed;

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.previous,
.next {
  box-sizing: border-box;

  padding: 7px;

  border: 1px solid var(--rebranding-md-color-brand-black);

  border-radius: 2px;

  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.previous {
  margin-right: 6px;
}

.next {
  margin-left: 6px;
}

.paginationHelper {
  color: var(--rebranding-md-color-grey-60);
}

.paginationHelperOption {
  cursor: pointer;
}

.paginationHelperOptionSelected {
  color: var(--rebranding-md-color-brand-purple);
}
