@import 'src/common/styles/settings.module.css';

.root {
  display: flex;
  flex-direction: column;

  input[type='file'] {
    display: none;
  }
}

.error {
  padding-top: var(--size-4);

  color: var(--rebranding-md-color-red);

  text-align: center;
}
