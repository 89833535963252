@import 'src/common/styles/settings.module.css';

.status {
  margin-left: var(--size-12);

  color: var(--rebranding-md-color-red);
}

.green {
  color: var(--rebranding-md-color-green);
}
