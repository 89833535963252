@import 'src/common/styles/settings.module.css';
.labelWrapper {
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--size-20);

  @media (--breakpoint-desktop) {
    flex-direction: row;
    justify-content: space-between;
  }
}
