@import 'src/common/styles/settings.module.css';
.column {
  display: grid;
  grid-template-columns: auto;
  column-gap: var(--gutter);
  row-gap: var(--size-20);

  padding-top: var(--size-20);

  @media (--breakpoint-desktop) {
    grid-template-columns: var(--3-cols);
  }
}

.buttonWrapper {
  button {
    width: 100%;
  }
  @media (--breakpoint-desktop) {
    button {
      width: unset;
    }
  }
}
