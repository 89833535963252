:root {
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-36: 36px;
  --size-40: 40px;
  --size-44: 44px;
  --size-48: 48px;
  --size-52: 52px;
  --size-64: 64px;
  --size-68: 68px;
  --size-80: 80px;
  --size-84: 84px;
  --size-88: 88px;
  --size-100: 100px;
  --size-120: 120px;
  --size-124: 124px;
  --size-160: 160px;
  --size-168: 168px;
  --size-200: 200px;

  --font-family-roboto: 'roboto', helvetica arial, sans-serif;

  --font-family-roobert-pro: 'Roobert PRO', helvetica arial, sans-serif;

  --font-family-ibmplexmono: 'ibmplexmono', helvetica arial, sans-serif;

  --font-weight-regular: 400;
  --font-weight-roobert-pro-semi-bold: 600;
  --font-weight-roobert-pro-bold: 700;

  --cols: 14;
  --gutter: 20px;
  --cell-length: 84px;
  --grid-size: 1440px;
  --sidebar-width: calc(var(--cell-length) + calc(2 * var(--gutter)));
  --2-cols: calc(var(--cell-length) * 2 + var(--gutter) * 1);
  --3-cols: calc(var(--cell-length) * 3 + var(--gutter) * 2);
  --4-cols: calc(var(--cell-length) * 4 + var(--gutter) * 3);
  --5-cols: calc(var(--cell-length) * 5 + var(--gutter) * 4);
  --6-cols: calc(var(--cell-length) * 6 + var(--gutter) * 5);
  --7-cols: calc(var(--cell-length) * 7 + var(--gutter) * 6);
  --8-cols: calc(var(--cell-length) * 8 + var(--gutter) * 5);
  --9-cols: calc(var(--cell-length) * 9 + var(--gutter) * 8);
  --10-cols: calc(var(--cell-length) * 10 + var(--gutter) * 9);
  --11-cols: calc(var(--cell-length) * 11 + var(--gutter) * 10);

  --size-merchant-logo: 298px;
  --size-merchant-logo-mobile: 213px;
}
