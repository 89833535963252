@import 'src/common/styles/settings.module.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: calc(var(--11-cols) + calc(2 * var(--gutter)));
}
