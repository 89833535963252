@import 'src/common/styles/settings.module.css';

.checkBox {
  padding: var(--size-28) 0 var(--size-12);
}

.footer {
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin-bottom: var(--size-12);
  }

  @media (--breakpoint-desktop) {
    flex-direction: row;
    align-items: center;

    > *:first-child {
      margin: 0 var(--size-20) 0 0;
    }
  }
}

.cardDivider {
  margin: var(--size-20) 0;

  border: 0;
  border-top: 1px solid var(--rebranding-md-color-grey-10);
}

.dropdownWrapper {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: 75%;
  }

  @media screen and (min-width: 1200px) {
    width: 33%;
  }
}

.helpText {
  margin-top: var(--size-8);
}

.link {
  color: var(--rebranding-md-color-brand-purple);

  cursor: pointer;

  text-decoration: none;

  &:hover {
    color: var(--rebranding-md-color-brand-purple-dark);
  }
}

.orange {
  color: var(--rebranding-md-color-orange);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.blue {
  color: var(--rebranding-md-color-blue);

  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.bold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}
