.button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  padding: 0;

  margin: var(--size-16) 0;

  background-color: transparent;

  border: none;

  cursor: pointer;

  &:focus {
    outline: 0;
  }

  text-decoration: none;
}
