@import 'src/common/styles/settings.module.css';

.optionSeparator {
  display: flex;
  align-items: center;
  margin: var(--size-20) 0;

  text-align: center;
}

.optionSeparator::before,
.optionSeparator::after {
  content: '';

  flex: 1;

  border-bottom: 1px solid var(--rebranding-md-color-grey-10);
}

.optionSeparator:not(:empty)::before {
  margin-right: var(--size-8);
}

.optionSeparator:not(:empty)::after {
  margin-left: var(--size-8);
}
