@import 'src/common/styles/settings.module.css';

.exportWrapper {
  display: flex;
  width: 100%;

  margin-bottom: var(--size-12);

  @media (--breakpoint-desktop) {
    max-width: 150px;

    margin-bottom: 0;
  }
}
