@import 'src/common/styles/settings.module.css';

:root {
  --sidebar-width: calc(var(--cell-length) + calc(2 * var(--gutter)));
}

.root {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--size-12);
  margin-top: var(--size-52);

  @media (--breakpoint-desktop) {
    margin-top: 0;
  }
}

.content {
  max-width: 100%;
  margin: 0;

  font-size: var(--size-12);
  line-height: var(--size-16);
  text-align: center;

  @media (--breakpoint-desktop) {
    padding-left: var(--sidebar-width);
  }
}

.highlight {
  font-weight: bold;
}

.root.top {
  margin: 0;
}

.root.local {
  background-color: #d4edda;
  color: #155724;
}

.root.development {
  background-color: #cce5ff;
  color: #004085;
}

.root.staging {
  background-color: #fff3cd;
  color: #856404;
}

.root.sandbox {
  background-color: var(--rebranding-md-color-orange-lightest);
  color: var(--rebranding-md-color-orange);
}

@media (min-width: 661px) {
  .content {
    font-size: var(--size-16);
    line-height: var(--size-20);
  }
}
