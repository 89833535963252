@import 'src/common/styles/settings.module.css';

:root {
  --card-index: 1;
  --arrow-width: var(--size-8);
  --tooltip-width: 259px;
}

.root {
  position: relative;

  display: flex;
  align-items: center;
}

.text {
  position: absolute;
  top: 150%;
  left: 500%;
  z-index: var(--card-index);

  display: inline-block;

  visibility: hidden;
  width: var(--tooltip-width);
  padding: var(--size-12);
  margin-left: calc(var(--tooltip-width) / -2);

  background-color: var(--color-white);
  border: 1px solid var(--rebranding-md-color-grey-10);
  border-radius: 2px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);
  opacity: 0;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-14);
  font-weight: var(--font-weight-regular);
  line-height: var(--size-16);
  text-align: left;

  transition: opacity 0.3s;

  a {
    color: var(--rebranding-md-color-brand-purple) !important;

    text-decoration: none;
  }

  @media (--breakpoint-desktop) {
    left: 50%;
  }
}

.root:hover .text {
  visibility: visible;

  opacity: 1;
}

.text::after {
  content: '';

  position: absolute;
  bottom: 100%;
  left: 18%;

  margin-left: calc(-1 * var(--arrow-width));

  border-color: transparent transparent var(--color-white) transparent;
  border-style: solid;
  border-width: var(--arrow-width);

  @media (--breakpoint-desktop) {
    left: 50%;
  }
}
