@import 'src/common/styles/settings.module.css';

@import 'src/common/styles/settings.module.css';
.root {
  position: absolute;

  bottom: var(--size-52);

  z-index: 2;

  align-self: center;
  display: flex;
  align-items: center;

  max-width: calc(var(--4-cols) + var(--4-cols) + var(--gutter));
  min-width: calc(var(--cell-length) * 3 + var(--gutter) * 4);
  min-height: var(--size-64);

  padding: var(--size-12);

  border-radius: 2px;

  @media (--breakpoint-desktop) {
    padding: 0 var(--size-12);
  }
}

.blue {
  background-color: var(--rebranding-md-color-blue);
}

.red {
  background-color: var(--rebranding-md-color-red);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: var(--size-64);
}

.iconWrapper {
  align-self: center;

  max-width: var(--size-32);
  min-width: var(--size-32);
  margin-right: var(--size-8);
}

.actionButton {
  padding-left: var(--size-12);

  margin-left: var(--size-12);

  border-left: 1px solid var(--rebranding-md-color-blue-dark);

  a,
  span {
    color: var(--rebranding-color-white);

    cursor: pointer;

    text-decoration: none;
  }
}
