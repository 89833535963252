@import 'src/common/styles/settings.module.css';

.row {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:not(:last-child) {
    margin-bottom: var(--size-40);
  }

  > * {
    flex: 1;
  }
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--breakpoint-desktop) {
    justify-content: flex-end;
  }
}

.buttonWrapperReset {
  width: 100%;

  @media (--breakpoint-desktop) {
    width: unset;
    min-width: 155px;
  }
}

.passwordHelper {
  padding: var(--size-4);
}
