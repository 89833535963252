@import 'src/common/styles/settings.module.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  padding: var(--size-12);

  background-color: var(--color-white);

  cursor: pointer;

  font-family: var(--font-family-roobert-pro);
  font-variant-numeric: lining-nums tabular-nums;
}

.active {
  background: var(--rebranding-md-color-grey-10);
}

.selected {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.disabled {
  color: var(--rebranding-md-color-grey-50);

  cursor: default;
}

.innerItem {
  padding: var(--size-12) var(--size-28);

  color: var(--rebranding-md-color-grey-80);
}
