@import 'src/common/styles/settings.module.css';

.root {
  display: block;

  font-family: var(--font-family-roobert-pro);
  font-size: var(--size-16);
  line-height: var(--size-20);
  overflow-wrap: break-word;
}

.regular {
  font-weight: var(--font-weight-regular);
}

.semiBold {
  font-weight: var(--font-weight-roobert-pro-semi-bold);
}

.caption {
  font-size: var(--size-14);
  line-height: var(--size-16);
}
