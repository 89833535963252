@import 'src/common/styles/settings.module.css';

.root {
  display: grid;
  grid-row-gap: var(--size-40);
  grid-template-columns: auto;

  padding: var(--size-20);
  margin: auto;

  > * {
    justify-self: center;

    text-align: center;
  }
}
